<script>
import Layout from '../../layouts/main';
import appConfig from '@/app.config';
import { clientMethods, masterMethods, listPaging, employeeMethods, checkPermission } from '@/state/helpers';
import Pagination from '@/components/Pagination/main.vue';
import KeyWordTag from '@/components/KeywordTag/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';

/**
 * Advanced-form component
 */
export default {
    page: {
        title: 'エンドクライアント一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Pagination,
        Layout,
        KeyWordTag,
        LoadingIcon,
        Footer
    },
    data() {
        return {
            listData: [],
            total: 0,
            limit: 10,
            page: 1,
            form: {
                name: '',
                pic: ''
            },
            has: {
                sale_staff: ''
            },
            listPLSale: [],
            perPageList: listPaging,
            configKeyword: {
                arrayTag: [],
                placeholder: '会社名　担当者　会社名(カナ)　電話番号　郵便番号　都道府県　住所　URL',
                isShowLable: true
            },
            checkShowFilter: false,
            isLoading: false,
            arrRoleClient: ['client.view', 'client.edit']
        };
    },
    watch: {
        limit: {
            deep: true,
            handler: function () {
                clearTimeout(this.retrieveListTimeout);
                this.retrieveListTimeout = setTimeout(() => {
                    this.getList();
                }, 500);
            }
        },
        page: {
            deep: true,
            handler: function () {
                this.getList();
            }
        },
        reload: function () {
            this.getList();
        }
    },
    mounted() {
        this.getList();
        this.getListMasterPLSale();
    },
    computed: {
        from: function () {
            return (this.page - 1) * this.limit + 1;
        },
        to: function () {
            let to = this.page * this.limit;
            return to > this.total ? this.total : to;
        }
    },
    methods: {
        checkPermission,
        ...masterMethods,
        ...clientMethods,
        ...employeeMethods,
        handleShowFilter() {
            this.checkShowFilter = !this.checkShowFilter;
        },
        getListMasterPLSale() {
            this.listEmployee('filters[role]=2&limit=999').then((data) => {
                this.listPLSale = data.data;
            });
        },
        goToEdit(path, item) {
            this.$router.push({ path: path, query: { id: item.id } });
        },
        getQueryString() {
            let query = [`page=${this.page}`, `limit=${this.limit}`, `sorts[id]=desc`];

            if (this.checkShowFilter) {
                if (this.form) {
                    for (const [key, value] of Object.entries(this.form)) {
                        if (key && value) query.push(`search[${key}]=${encodeURIComponent(value)}`);
                    }
                }
                if (this.has) {
                    for (const [key, value] of Object.entries(this.has)) {
                        if (key && value) query.push(`has[${key}]=${encodeURIComponent(value)}`);
                    }
                }
            } else {
                if (this.configKeyword.arrayTag.length > 0) {
                    query.push(`q=${this.configKeyword.arrayTag.toString()}`);
                }
            }

            return query.join('&');
        },
        getList() {
            this.isLoading = true;
            let query = this.getQueryString();
            this.listClient(query).then((data) => {
                this.listData = data.data;
                this.total = data._metadata.total;
                this.limit = data._metadata.limit * 1;
                if (!this.listData.length && this.page !== 1) {
                    this.page = 1;
                }
                this.isLoading = false;
            });
        },
        onChange() {
            this.page = 1;
        },
        search() {
            let data = {
                form: this.form,
                q: this.q,
                has: this.has,
                filter: this.configKeyword.arrayTag
            };
            localStorage.setItem('client', JSON.stringify(data));
            this.getList();
        },
        clear() {
            this.form = {
                name: '',
                pic: ''
            };
            this.q = '';
            this.has = {
                sale_staff: ''
            };
            this.configKeyword.arrayTag = [];
            let data = {
                form: this.form,
                filter: this.configKeyword.arrayTag,
                has: this.has
            };
            localStorage.setItem('client', JSON.stringify(data));
            this.getList();
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">エンドクライアント検索</h3>
                    </div>
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-6">
                                <form class="needs-validation" autocomplete="off">
                                    <!--                  <div class="text-right mb-3">-->
                                    <!--                    <button-->
                                    <!--                      type="button"-->
                                    <!--                      class="btn btn-success"-->
                                    <!--                      @click="handleShowFilter"-->
                                    <!--                    >-->
                                    <!--                      {{ !checkShowFilter ? "フィルター" : "閉じる" }}-->
                                    <!--                    </button>-->
                                    <!--                  </div>-->
                                    <div v-if="!checkShowFilter" class="row form-group">
                                        <div class="col-sm-12">
                                            <KeyWordTag :keyword="form.keywords" :config="configKeyword" class="flex-fill" />
                                        </div>
                                    </div>
                                    <div v-if="checkShowFilter">
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">会社名</label>
                                            <div class="col-sm-9">
                                                <input id="validationCustom05" v-model="form.name" type="text" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">PL営業担当</label>
                                            <div class="col-sm-9">
                                                <div class="d-flex">
                                                    <select v-model="has.sale_staff" class="form-control" id="">
                                                        <option :value="item.id" v-for="item in listPLSale" :key="item.id">
                                                            {{ item.name }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">エンドクライアント担当名</label>
                                            <div class="col-sm-9">
                                                <input id="validationCustom05" v-model="form.pic" class="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="text-center mt-4 position-relative">
                                        <button type="button" @click="clear()" class="btn btn-danger btn-light">
                                            {{ $t('btn.clear') }}
                                        </button>
                                        <button type="button" @click="search()" class="btn btn-primary ml-3">
                                            {{ $t('btn.search') }}
                                        </button>
                                        <a @click="handleShowFilter" class="filter" href="javascript:void(0)">
                                            <img :src="require('@/assets/images/settings.png')" width="30px" height="30px" alt="" />
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">
                            エンドクライアント検索結果 <span>件数（{{ total }})</span>
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="pagination__items clearfix pb-3 text-right">
                            <span class="pagination__items__label mr-4"> {{ total }} 件中 {{ from }}-{{ to }} 件表示 </span>
                            <span>表示件数</span>
                            <select v-model="limit" class="pagination__items__select ml-2" @change="onChange">
                                <option v-for="value in perPageList" :key="value" :label="value + '件'" :value="value"></option>
                            </select>
                        </div>
                        <div class="table-responsive">
                            <table class="table mb-0 table-bordered">
                                <thead>
                                    <tr>
                                        <th>会社名</th>
                                        <th>会社名(カナ)</th>
                                        <th>実施中キャンペーン数</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody v-if="listData.length > 0 && !isLoading">
                                    <template>
                                        <tr v-for="item in listData" :key="item.id">
                                            <td>
                                                <a href="javascript:void(0)" @click="goToEdit('/client/form/view', item)">{{ item.name }}</a>
                                            </td>
                                            <td>{{ item.kana_name ? item.kana_name : '' }}</td>
                                            <td>
                                                <a
                                                    href="javascript:void(0)"
                                                    @click="
                                                        $router.push({
                                                            path: '/campaign/list',
                                                            query: { client_id: item.id }
                                                        })
                                                    "
                                                    >({{ item.campaigns_count }})</a
                                                >
                                            </td>

                                            <td align="center">
                                                <a
                                                    href="javascript:void(0)"
                                                    class="text-info border-right-1"
                                                    @click="goToEdit('/client/form/view', item)"
                                                    >詳細</a
                                                >
                                                <a
                                                    v-if="checkPermission(arrRoleClient[1])"
                                                    href="javascript:void(0)"
                                                    class="text-success"
                                                    @click="goToEdit('/client/form', item)"
                                                    >編集</a
                                                >
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody v-else-if="isLoading">
                                    <template>
                                        <tr style="position: relative; height: 80px">
                                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                                <div class="d-flex justify-content-center">
                                                    <LoadingIcon />
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody v-else>
                                    <template>
                                        <tr style="position: relative; height: 80px">
                                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                                <p class="mt-3 text-center">
                                                    {{ $t('notificationCommon.searchEmpty') }}
                                                </p>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>

                        <pagination v-if="total && listData.length > 0 && !isLoading" :total="total" :page.sync="page" :per-page="limit"></pagination>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </Layout>
</template>

<style lang="scss" scoped>
td,
th {
    vertical-align: middle;
}
</style>
